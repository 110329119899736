html {
	--wired-calendar-bg: none;
	--wired-calendar-color: black;
	--wired-slider-knob-zero-color: black;
	--wired-slider-knob-color: black;
	--wired-slider-bar-color: black;
	--wired-video-highlight-color: black;
	--wired-video-highlight-color: black;
	--wired-search-input-color: black;
} 
.search-svg {
	fill: var(--wired-search-input-color)
}
.Logo {
	display: flex;
	align-items: center;
	font-size: calc(14px + 2vmin);
	max-height: 10em;
}

/* button {
	cursor: pointer;
} */

/* .POV-statement {
	text-align: left;
	flex-grow: 1;
} */

/* .Annotation {
	margin: 1rem;
} */

.App {
	text-align: center;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	position: relative;
}

.App-footer {
	position: fixed;
	bottom: .5rem;
	width: 100%;
	text-align: center;
}

/* 
.Exercise {
	display: flex;
	padding: .5rem;
	flex-wrap: wrap-reverse;
} */

/* .Exercise-footer {
	height: 5em;
	display: flex;
	justify-content: flex-start;
	padding: 2em;
} */

/* .App-logo {
	height: 2rem;
.App-logo {
	height: 2em;
	pointer-events: none;
	margin: 0.5rem;
} */

/* @media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
} */

/* .POV-example {
	text-align: left;
} */

/* .App-header {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
} */

/* .Exercise-header {
	display: flex;
} */

.App-body {
	flex-grow: 1;
	padding: 0.5rem;
	display: flex;
	flex-wrap: wrap;
}

/* .Time-box {
	display: flex;
	flex-direction: row;
	white-space: nowrap;
} */

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

/* .Progress {
	margin: auto;
	margin-right: 2rem;
} */

.MuiBadge-badge {
	height: 30px !important;
	width: 30px !important;
	border-radius: 30px !important;
}

/* .component {
	height: 100% !important;
} */
.hide {
	max-width: 0%;
	transition: 0.75s;
	overflow: hidden;
	transition-timing-function: ease-in-out;
}
.displayNone {
	display: "none"
}
.show {
	max-width: 100%;
	transition: 0.75s;
	transition-timing-function: ease-in-out;
	min-width: 12rem;
}

.blurred {
	filter: blur(15px) !important;
	pointer-events: none;
}
.Annotation-tools button {
	width: 50%;
}
.clickable:hover {
	cursor: pointer;
	box-shadow: 0 0 8px 0px;
}
.clickable {
	width: auto;
	display: inline-block;
}

.width-100, .width-100 .MuiBadge-root {
	width:100% !important;
}
.width-0 {
	width: 0% !important;
	padding: 0 !important;
}

.width-normal {
	transition: 2s;
	transition-timing-function: ease-in-out;
}