.LayoutWires {
	width: 100%;
	max-width: 1200px;
	padding: 1em;
	box-sizing: border-box;

	.menu {
		min-width: 200px;
		display: flex;
		flex-grow: 0;

		&.row {
			margin-bottom: 1em;

			.logo {
				margin-right: .5em;
			}
		}

		&.column {
			margin-right: 1em;

			.logo {
				margin-bottom: .5em;
			}
		}

		.menu-title {
			margin: 2em;
		}
	}

	.body-content {
		// max-height: 70vh;
		overflow: auto;
		width: 100%;
		// padding: 20px;

		.body-section {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			&.grow>* {
				flex-grow: 1;
			}

			.spacer {
				flex-grow: 2;
			}

			.display-flex-row {
				display: flex;
				justify-content: space-evenly;
				align-items: center;
				flex-direction: row;
			}

			.display-flex-column {
				display: flex;
				justify-content: space-evenly;
				align-items: center;
				flex-direction: column;
			}
		}
	}
}

#Top-Layout {
	flex-direction: column;
}

#Side-Layout {
	flex-direction: row;
}